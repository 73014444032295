/**
 * The class GeoDetection will inject the wirtgen-
 * geo-detection js-script to the dom to get the
 * values (address, country and language).
 *
 *  ```js
 *    GeoDetection.create()
 *      .inject()
 *       .then(({ address, country, language }) => {
 *         console.log(address, country, language);
 *    })
 *    .catch((err) => {
 *      console.error(err);
 *    });
 * ```
 */
export default class GeoDetection {
  /**
   * @param url
   */
  constructor(url = null) {
    //
    this.url = url !== null ? url : this.getScriptUrl();
  }

  /**
   * Create a new service
   * @param url A custom url to load the detection script from
   * @returns {GeoDetection}
   */
  static create(url = null) {
    return new GeoDetection(url);
  }

  /**
   * Return the default url for the detection service
   */
  getScriptUrl() {
    return `${window.location.origin}/ocs/detection/detection.js`;
  }

  /**
   * Inject the script. The function will return
   * a Promise which if resolves, contains the values
   * of the detection result and on reject will pass
   * the raised error event.
   * @returns {Promise<unknown>}
   */
  inject() {
    return new Promise((resolve, reject) => {
      // check if the script are already available eg. already
      // included within the header of the current page.
      if (window.detectionresult) {
        resolve(this._result(window.detectionresult));
        return;
      }

      // create new script tag
      const script = document.createElement('script');

      // add success handler
      script.onload = () => {
        // abort if the main var holding the results
        // of the geo-detection are not available
        if (!window.detectionresult) console.error('Unable to load detection-service!');

        // get values and resolve
        resolve(this._result(window.detectionresult));
      };

      // add error handler
      script.onerror = (e) => {
        // reject on error
        reject(e);
      };

      // load script by setting the src
      // and adding it to the dom
      script.src = this.url;
      document.head.appendChild(script);
    });
  }

  /**
   * Return an object containing the result
   * of the detection service.
   * @param detectionresult
   * @returns {{country: (string|null), address: null, language: (string|null)}}
   * @private
   */
  _result(detectionresult) {
    return {
      address: this._address(detectionresult),
      country: this._country(detectionresult),
      language: this._language(detectionresult),
    };
  }

  /**
   * Return address of the detection result
   */
  _address(detectionresult) {
    //
    const data = this._data(detectionresult);

    return data && data.address && data.address.length > 0 ? data.address.toLowerCase() : null;
  }

  /**
   * Return country of the detection result
   * @param detectionresult
   * @returns {string|null}
   * @private
   */
  _country(detectionresult) {
    //
    const data = this._data(detectionresult);

    return data && data.country && data.country.length > 0 ? data.country.toLowerCase() : null;
  }

  /**
   * Return language of the detection result
   * @param detectionresult
   * @returns {string|null}
   * @private
   */
  _language(detectionresult) {
    //
    const data = this._data(detectionresult);

    return data && data.language && data.language.length > 0 ? data.language.toLowerCase() : null;
  }

  /**
   * Get the base data object of the detection
   * result loaded by the script.
   * @param detectionresult
   * @returns {*|null}
   * @private
   */
  _data(detectionresult) {
    const redirect = detectionresult?.data?.ipDetection?.redirect ? detectionresult.data.ipDetection.redirect : null;
    const ipDetection = detectionresult?.data?.ipDetection ? detectionresult.data.ipDetection : null;
    return redirect ? redirect : ipDetection;
  }
}
