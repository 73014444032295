import Logger from './Logger';

/**
 * I18n helps to get translations for the geodetection-popup.
 */
export default class I18n {
  /**
   *
   * @param destLang
   * @param destCountry
   * @param sourceLang
   * @param sourceCountry
   * @returns {Promise<unknown>}
   */
  t(destLang, destCountry, sourceLang, sourceCountry) {
    return new Promise((resolve, reject) => {
      //
      const [lang, country] = this.getBrowserLocale(destLang, destCountry);

      Logger.log('i18n => got locale: ' + lang + '-' + country);

      this._fetch(lang, country)
        .then((translations) => {
          // check if the translations exists
          if (!translations.countries[sourceCountry]) {
            reject('Translation for source-country not found!');
            return;
          }
          if (!translations.countries[destCountry]) {
            reject('Translation for destination-country not found!');
            return;
          }

          if (
            !translations.content.selectCountry ||
            !translations.content.selectedLocation ||
            !translations.content.continue ||
            !translations.content.currentLocation ||
            !translations.content.switch1 ||
            !translations.content.switch2 ||
            !translations.content.toHomepage ||
            !translations.content.changeCountryAndLanguage ||
            !translations.content.contentUnavailable
          ) {
            reject('Unsupported translation format!');
            return;
          }

          // construct translated pop-up content
          const translated = {
            selectCountry: translations.content.selectCountry,
            selectedLocation: translations.content.selectedLocation,
            continue: translations.content.continue,
            currentLocation: translations.content.currentLocation,
            switch1: translations.content.switch1,
            switch2: translations.content.switch2,
            toHomepage: translations.content.toHomepage,
            changeCountryAndLanguage: translations.content.changeCountryAndLanguage,
            contentUnavailable: translations.content.contentUnavailable,
            sourceCountry: translations.countries[sourceCountry],
            destinationCountry: translations.countries[destCountry],
          };

          resolve(translated);
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * Try to get the locale of the user from the browser.
   * @param destLang
   * @param destCountry
   * @returns {string[]|(string|*)[]|*[]}
   */
  getBrowserLocale(destLang, destCountry) {
    // get the locale from the browser
    const locale = navigator.language;

    // if no locale is set, return the default language and country
    if (!locale) {
      return [destLang, destCountry];
    }

    // if the locale doesn't contain a country, return the language and the default country
    if (locale.indexOf('-') < 0) {
      return [locale, destCountry];
    }

    return [locale.split('-')[0], locale.split('-')[1].toLowerCase()];
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  _fetch(lang, country) {
    //
    const url = this._getApiUrl(lang, country);
    //
    return new Promise((resolve, reject) => {
      // abort if the url isn't available
      if (!url) reject();
      //
      fetch(url)
        .then((response) => {
          //
          if (!response.ok) reject(response.statusText);

          return response.text();
        })
        .then((data) => {
          // try to parse the fetched translations
          let json;
          try {
            json = JSON.parse(data);
          } catch (err) {
            reject(err);
          }

          // validate received json data
          if (
            json['countries'] === undefined ||
            Object.keys(json['countries']).length === 0 ||
            json['countries'][country] === undefined ||
            json['countries'][country].length === 0 ||
            json['content'] === undefined ||
            json['content']['selectCountry'] === undefined ||
            json['content']['selectedLocation'] === undefined ||
            json['content']['continue'] === undefined ||
            json['content']['currentLocation'] === undefined ||
            json['content']['switch1'] === undefined ||
            json['content']['switch2'] === undefined ||
            json['content']['toHomepage'] === undefined ||
            json['content']['changeCountryAndLanguage'] === undefined ||
            json['content']['contentUnavailable'] === undefined
          ) {
            reject("Couldn't load translations! Received non-valid json data.");
          }

          //
          resolve(json);
        })
        .catch((err) => reject(err));
    });
  }

  /**
   * Get url to fetch translations from
   * @param lang
   * @param country
   * @returns {string|boolean|*}
   * @private
   */
  _getApiUrl(lang, country) {
    // use static url if in development mode
    if (window.location.href.indexOf('T-Geodetection.html') >= 0) {
      return `https://www-rvw.wirtgen-group.com/${country}-${lang}/technical-pages/educated_guess/index.json`;
    }

    // get url from global configuration
    const url = window.CONF.EDUCATED_GUESS_TRANSLATIONS;
    if (!url || url.length === 0) {
      console.error(
        "Key 'EDUCATED_GUESS_TRANSLATIONS' missing in global config. Please specify the uri to get educated-guess translations from!"
      );
      return false;
    }

    // check if the uri contains the placeholders for the lang and country
    if (url.indexOf('%lang') < 0 || url.indexOf('%country') < 0) {
      console.error('No placeholders (%lang/%country) for lang and country within uri found!');
      return false;
    }

    // replace placeholders
    return url.replace('%lang', lang).replace('%country', country);
  }
}
