'use strict';

// Load jQuery Plugins because they're in global namespace :(
import 'bootstrap';

// import 'core-js/modules/es6.promise';
// import 'core-js/modules/es6.array.iterator';
// ###### Import jQuery ######
import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';
import './libs/public-path';
import './libs/namespace';
// Custom Components
import './_components';

import './hookurl';
import './ocs-links';
import './libs/scroll-tracking';

// Allows different styling with/without javascript support
$('html').removeClass('no-js').addClass('js');
