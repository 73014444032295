import I18n from './I18n';
import { PopUpMarkup } from './PopUpMarkup';

export default class PopUp {
  /**
   * @param destLang
   * @param destCountry
   * @param sourceLang
   * @param sourceCountry
   */
  constructor(destLang, destCountry, sourceLang, sourceCountry) {
    this.markup = null;
    this.destLang = destLang;
    this.destCountry = destCountry;
    this.sourceLang = sourceLang;
    this.sourceCountry = sourceCountry;
    this.flagPageHandler = () => {
      window.location.href = 'https://www.wirtgen-group.com/?q=ngd';
    };

    // init i18n service
    this.i18n = new I18n();
  }

  /**
   * Set the handler called after the user has
   * clicked the change button within the pop-up
   * @param handler
   */
  setChangeHandler(handler) {
    this.changeHandler = handler;
  }

  /**
   * Set the handler called after the user has
   * clicked the close button within the pop-up
   * @param handler
   */
  setCloseHandler(handler) {
    this.closeHandler = handler;
  }

  /**
   *
   * @param urlExists Whether the url of the current page exists in the lang/country of the user.
   */
  show(urlExists) {
    this.i18n
      .t(this.destLang, this.destCountry, this.sourceLang, this.sourceCountry)
      .then((translations) => {
        //
        this.markup = PopUpMarkup.get(
          translations,
          urlExists,
          this.destLang,
          this.changeHandler,
          this.closeHandler,
          this.flagPageHandler
        );

        document.body.append(this.markup);
        this.markup.showModal();
      })
      .catch((err) => console.error(err));
  }

  /**
   * Hide the pop-up
   */
  hide() {
    this.markup.close();
    this.markup = null;
  }
}
