import GeoDetection from './GeoDetection';
import UrlTransform from './UrlTransform';

export default class GeoLinkReplace {
  constructor() {
    //
    this.fallbackCountries = [
      { lang: 'de', country: 'de' },
      { lang: 'en', country: 'gb' },
      { lang: 'zh', country: 'cn' },
      { lang: 'fr', country: 'fr' },
      { lang: 'it', country: 'it' },
      { lang: 'pt', country: 'pt' },
      { lang: 'ru', country: 'ru' },
      { lang: 'es', country: 'es' },
      { lang: 'tr', country: 'tr' },
    ];
  }

  /**
   *
   * @returns {GeoLinkReplace}
   */
  static create() {
    return new GeoLinkReplace();
  }

  /**
   *
   * @param selector
   * @returns {Promise<unknown>|null}
   */
  inject(selector) {
    return new Promise((resolve, reject) => {
      // get elements
      const elements = document.querySelectorAll(selector);

      // abort if no elements are available
      if (!elements) {
        resolve();
        return;
      }

      // geo-locate user
      GeoDetection.create()
        .inject()
        .then(({ country, language }) => {
          //
          let langCountry = { language, country };

          // loop over the links and replace the lang/ country
          // values with them from the location service
          elements.forEach((element) => {
            //
            if (langCountry.language === null || langCountry.country === null) {
              //
              langCountry = this.getFallback(element);

              //
              if (langCountry === null) {
                console.error('Could not find fallback country for language ', lang);
                return;
              }
            }

            let href = element.getAttribute('href').trim();
            const urlTransform = new UrlTransform(href);

            href = urlTransform.transformTo(langCountry.language, langCountry.country);
            if (!href) {
              console.log('   |- Unable to transform href for element: ', element.getAttribute('href'));
              return;
            }

            // upd
            element.setAttribute('href', href);
          });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   *
   * @param element
   * @returns {{country: *, language: *}|null}
   */
  getFallback(element) {
    const lang = element.getAttribute('data-language');
    const item = this.fallbackCountries.find((item) => item.lang === lang);

    // abort if no fallback are available found
    if (!item) {
      return null;
    }

    return {
      language: lang,
      country: item.country,
    };
  }
}
