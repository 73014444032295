/**
 * Load SVG sprites with AJAX
 */
function loadIcons() {
  const ajax = new XMLHttpRequest();
  ajax.open('GET', CONF.SVG_SPRITE);
  ajax.send();
  ajax.onload = () => {
    document.body.insertAdjacentHTML('afterbegin', ajax.response);
  };
}

/**
 * Markup for include icon
 * @param  {String} name         [icon name]
 * @param  {String} [classes=''] [additional classes]
 * @return {String}              [html]
 */
const icon = (name, classes = '') =>
  `<svg class="icon icon-${name} ${classes}"><use xlink:href="#icon-${name}"></use></svg>`;

/**
 * load a SVG File with ajax and append it inline,
 * required for CSS styling
 * @param {string} selector [selector='.inline-svg']
 */
function inlineSVG(selector = '.inline-svg') {
  const $elements = $(selector);
  if (!$elements.length > 0) {
    return false;
  }

  // ===========================================================================
  // == MAIN jQuery Plugin Function                                           ==
  // ===========================================================================
  $elements.each(function() {
    const svgUrl = this.dataset.url;

    if (svgUrl) {
      // Do AJAX
      $.ajax({
        url: svgUrl,
        dataType: 'xml'
      }).done(ajax => {
        this.appendChild(ajax.documentElement);
        this.firstElementChild.setAttribute('class', 'icon');
      });
    } else {
      this.querySelector('svg').setAttribute('class', 'icon');
    }
  });
}

/* ### Initialize module ### */
loadIcons();
inlineSVG('.inline-svg');

export { icon, inlineSVG };
