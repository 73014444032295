//
import { track } from './TrackingService';

// define the milestone data
const milestones = [
  { percent: 100, value: '100%', tracked: false },
  { percent: 75, value: '75%', tracked: false },
  { percent: 50, value: '50%', tracked: false },
  { percent: 25, value: '25%', tracked: false },
];

/**
 *
 */
window.addEventListener('scroll', () => {
  //
  const doc = document.documentElement;
  const documentHeight = doc.scrollHeight;
  const innerHeight = doc.clientHeight;
  const offset = documentHeight - innerHeight;
  const percentScrolled = Math.floor((window.scrollY / offset) * 100);

  //
  milestones.forEach((milestone, index) => {
    if (index === 0) {
      if (percentScrolled === milestone.percent && !milestone.tracked) {
        //
        milestoneReached(milestone);
      }
    } else {
      if (percentScrolled > milestone.percent && !milestone.tracked) {
        //
        milestoneReached(milestone);
      }
    }
  });
});

/**
 * Called after a single milestone has been reached
 * @param milestone
 */
const milestoneReached = (milestone) => {
  //
  milestone.tracked = true;
  //
  doTrack(milestone.value);
};

/**
 * Custom function to track the scroll depth
 * @param percent
 */
const doTrack = (percent) => {
  //
  const props = {
    eventtype: 'scrollevent',
    scrolldepth: percent,
  };

  // call the tracking service
  track(props);
};
