//
import GeoLinkReplace from './libs/GeoLinkReplace';

// create selector
const selector = 'a.ocs';

console.log('Starting OCS-Link component..');

document.addEventListener('DOMContentLoaded', () => {
  console.log('OCS-LINKS => DOMContentLoaded');
  // start only if we have some elements
  if (document.querySelectorAll(selector).length > 0) {
    console.log('ocs-links => found %s links...', document.querySelectorAll(selector).length);
    //
    GeoLinkReplace.create()
      .inject(selector)
      .catch((err) => console.error(err));
  } else {
    console.log('no ocs-links (%s) found on this page', selector);
  }
});
