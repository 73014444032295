import Cookie from './Cookie';

/**
 *
 */
export default class Logger {
  /**
   *
   * @param msg
   */
  static log(msg) {
    // only log if test mode is active
    if (!Logger.active()) return;

    console.log(msg);
  }

  /**
   *
   * @param msg
   */
  static info(msg) {
    // only log if test mode is active
    if (!Logger.active()) return;

    console.info(msg);
  }

  /**
   * Determine whether logging is active or not
   * @returns {string}
   */
  static active() {
    return Cookie.getCookie('WirtgenEducatedGuessTestMode');
  }
}
