/**
 * The UrlTransform class will transform a single
 * url from one language/country to another. Eg.
 * from wirtgen.com/de-de to wirtgen.com/en-gb
 */
export default class UrlTransform {
  /**
   * @param url
   */
  constructor(url) {
    // ensure we have a absolute url
    if (url.indexOf('http') < 0) {
      const origin = window.location.origin;

      // check if we must add a slash between the parts
      if (origin.lastIndexOf('/') !== origin.length - 1 && url.indexOf('/') !== 0) {
        this.url = new URL(`${window.location.origin}/${url}`);
      } else {
        this.url = new URL(`${window.location.origin}${url}`);
      }
    } else {
      this.url = new URL(url);
    }
  }

  /**
   * Transform the base url to the given language and country.
   * @param lang                The target language the resulting url should point to
   * @param country             The target country the resulting url should point to.
   * @returns {string|boolean}  The transformed url or false on error.
   */
  transformTo(lang, country) {
    //
    if (!lang || !country) return false;

    //
    const parts = this.url.pathname.split('/');

    // see if we have to handle an ocs link
    if (this.url.pathname.indexOf('ocs') > 0) {
      // check if valid
      if (!this._valid(parts[2])) return false;
      // replace just the lang/country part
      parts[2] = this._transform(parts[2], lang, country);
    } else {
      // check if valid
      if (!this._valid(parts[1])) return false;
      // replace just the lang/country part
      parts[1] = this._transform(parts[1], lang, country);
    }

    return `${this.url.origin}${parts.join('/')}`;
  }

  /**
   * Transform the base url to the home page of the given lang and country (http://wirtgen.com/en-gb/)
   * If no lang and country are given, redirect to the flag-page where the user are able to select his
   * lang/country.
   * @param lang                The target language the resulting url should point to
   * @param country             The target country the resulting url should point to.
   */
  transformToHome(lang = null, country = null) {
    //
    if (!lang || !country) {
      return `${this.url.origin}`;
    }
    //
    return `${this.url.origin}/${lang}-${country}/`;
  }

  /**
   * Within wirtgen links the lang and country values are
   * separated with a dash.
   * @param langCountry
   * @returns {boolean}
   * @private
   */
  _valid(langCountry) {
    return langCountry.indexOf('-') === 2;
  }

  /**
   * Replaces the language and country part of an url
   * with the given lang and country params.
   * @param from            The lang/country string part of a url (de-de)
   * @param lang            The new language you want to transform the url to
   * @param country         The new country you want to transform the url to
   * @returns {boolean|*}   asdfasdf
   * @private
   */
  _transform(from, lang, country) {
    //
    const langCountryParts = from.split('-');
    langCountryParts[0] = lang;
    langCountryParts[1] = country;
    return langCountryParts.join('-');
  }
}
