/**
 * hookUrl will create a cookie with the value
 * of the url parameter HOOK_URL
 */
const hookUrl = () => {
  // parse url-search-params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // try getting value of url param
  const hook = urlParams.get('HOOK_URL');
  if (hook) {
    // set cookie if value exists
    document.cookie = 'hookurl=' + hook + '; max-age=1800; samesite=lax; path=/';
  }
};

//
hookUrl();
