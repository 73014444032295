import Cookie from './lib/Cookie';
import EducatedGuess from './lib/EducatedGuess';
import Logger from './lib/Logger';

Logger.log('EducatedGuess => test-mode active...');
let currentUrl = null;

// check environment as we need to simulate
// the url while in development environment eg.
// on localhost
if (window.location.href.indexOf('T-Geodetection.html') >= 0) {
  currentUrl = 'https://www-parts-rvw.wirtgen-group.com/ocs/it-it/parts/fahrwerk-raupenantrieb-200-c/';
} else {
  currentUrl = window.location.href;
}

// initialize educated guess
const educatedGuess = new EducatedGuess(currentUrl);

//
const t = setTimeout(() => {
  Logger.log(`EducatedGuess => running after ${EducatedGuess.POP_UP_DELAY}ms...`);
  //
  educatedGuess.run();
  clearTimeout(t);
}, EducatedGuess.POP_UP_DELAY);
