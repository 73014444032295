/**
 * Global wirtgen namespace to register frontend components
 * in. Used for uni-directional component communication.
 * @type {{}}
 */
window.wirtgen = {};

// Before registering your own component use the following line
// within the components js.
// window.wirtgen = wirtgen ? wirtgen : {};

// To define your own component then use:
// wirtgen.mycomponent = {
//   someFunc: () => {}
// }

// This function are then callable via:
// wirtgen.mycomponent.someFunc()
