/**
 * The UrlParser class tries to determine the lang and country
 * pair of the given url.
 */
export default class UrlParser {
  /**
   * @param url
   */
  constructor(url = window.location.href) {
    //
    this.url = new URL(url);
    this.language = null;
    this.country = null;
  }

  /**
   * Parse the base-url to determine the lang and country values.
   * @returns {boolean}   Return whether parsing was successful eg. a lang/country pair was found.
   */
  parse() {
    // find lang and country abbreviations
    const langAndCountry = this._findLangCountry(this.url.pathname);

    // abort if nothing was found
    if (langAndCountry === undefined || !langAndCountry.length > 0) {
      return false;
    }

    // get single two digits identifiers for lang and country,
    // eg. from 'de-de' to [de, de]
    const parts = String(langAndCountry).split('-');

    // ensure valid data
    if (parts.length !== 2) {
      return false;
    }

    // update values
    this.language = parts[0];
    this.country = parts[1];

    return true;
  }

  /**
   * Try to find a lang/country pair within the pathname part
   * of a url.
   * @param pathname  The pathname part of a url. This will be the pathname of the base-url
   * @returns {*}     The lang/country values included within the url
   * @private
   */
  _findLangCountry(pathname) {
    const result = [];
    const regex = /[a-z]{2}-[a-z]{2}/gm;
    let m;

    while ((m = regex.exec(pathname)) !== null) {
      // prevent infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      m.forEach((match, groupIndex) => {
        result.push(match);
      });
    }

    // only return first match
    return result[0];
  }
}
