/**
 * Class Cookie helps to set and get cookies
 */
export default {
  /**
   * Sets a cookie in the browser of the user
   * @param name            The name of the cookie
   * @param value           The value of the cookie
   * @param expirationDays  The number of days before the cookie will expire
   */
  setCookie: (name, value, expirationDays = 7) => {
    const now = new Date();
    now.setTime(now.getTime() + expirationDays * 24 * 3600 * 1000);
    const expires = `expires=${now.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  },

  /**
   * Return the value of a cookie with the given name. If no cookie
   * was found we'll return undefined.
   * @param name  The name of the cookie you want to get the value from
   * @returns {string|undefined}
   */
  getCookie: (name) => {
    //
    const cookieName = `${name}=`;
    const decodedCookies = decodeURIComponent(document.cookie);
    const c = decodedCookies.split(';');

    for (let i = 0; i < c.length; i++) {
      // get a single cookie
      let cookie = c[i];
      //
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      //
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }

    return undefined;
  },

  /**
   * Remove the cookie with the given name
   * @param name
   */
  removeCookie: (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  },
};
