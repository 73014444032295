/**
 * Use this function to track some events. If the kits
 * tracking service are available, the data will be passed
 * to kits otherwise the data will be logged to the console.
 */
export const track = (trackingData) => {
  // are the kits tracking service available?
  const tracker = window?.trackingService ? window.trackingService : null;

  // if not log the data to the console...
  if (tracker === null) {
    console.info('core/js/libs/trackingservice.js => track: ', trackingData);
    return;
  }

  // ...otherwise pass the data to kits
  tracker.track(trackingData);
};
