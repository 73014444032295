export class PopUpMarkup {
  /**
   *
   * @param translations
   * @param urlExists
   * @param destLang
   * @param changeHandler
   * @param closseHandler
   * @param flagPageHandler
   * @returns {HTMLDialogElement}
   */
  static get(translations, urlExists, destLang, changeHandler, closseHandler, flagPageHandler) {
    //
    const dialog = document.createElement('dialog');
    dialog.setAttribute('id', 'geodetection-popup');
    dialog.classList.add('geodetection-popup');

    if (!urlExists) {
      dialog.classList.add('geodetection-popup--content-not-available');
    }

    //
    const section = document.createElement('section');

    //
    const header = PopUpMarkup.#get_header(translations);
    const content = PopUpMarkup.#get_content(translations, urlExists, destLang, changeHandler, closseHandler);
    const footer = PopUpMarkup.#get_footer(translations, flagPageHandler);

    //
    section.append(header, content, footer);
    //
    dialog.append(section);

    return dialog;
  }

  /**
   *
   * @param translations
   * @returns {HTMLElement}
   */
  static #get_header(translations) {
    const { selectCountry } = translations;

    const header = document.createElement('header');
    header.classList.add('geodetection-popup__header');

    const h3 = document.createElement('h3');
    h3.classList.add('hdl--3');
    h3.textContent = selectCountry;
    header.appendChild(h3);

    return header;
  }

  /**
   *
   * @param translations
   * @param urlExists
   * @param destLang
   * @param changeHandler
   * @param closseHandler
   * @returns {HTMLElement}
   */
  static #get_content(translations, urlExists, destLang, changeHandler, closseHandler) {
    const {
      selectedLocation,
      continue: continueLabel,
      currentLocation,
      switch1,
      switch2,
      toHomepage,
      sourceCountry,
      destinationCountry,
    } = translations;

    const article = document.createElement('article');
    article.classList.add('geodetection-popup__body');

    const columnLeft = document.createElement('div');
    columnLeft.classList.add('geodetection-popup__body-column');
    columnLeft.classList.add('geodetection-popup__column-left');

    const contentElLeft = document.createElement('p');
    contentElLeft.innerHTML = `${selectedLocation}<br/>${sourceCountry}`;

    const btnContinue = document.createElement('button');
    btnContinue.classList.add('btn');
    btnContinue.classList.add('btn-secondary');
    btnContinue.classList.add('geodetection-popup__btn-continue');
    btnContinue.innerHTML = continueLabel;
    btnContinue.addEventListener('click', closseHandler);

    columnLeft.append(contentElLeft, btnContinue);

    const columnRight = document.createElement('div');
    columnRight.classList.add('geodetection-popup__body-column');
    columnRight.classList.add('geodetection-popup__column-right');

    const contentElRight = document.createElement('p');
    contentElRight.innerHTML = `${currentLocation}<br/>${destinationCountry}`;

    const contentEl2Right = document.createElement('span');
    contentEl2Right.classList.add('geodetection-popup__switch-to-homepage');
    contentEl2Right.innerHTML = toHomepage;

    const btnSwitch = document.createElement('button');
    btnSwitch.classList.add('btn');
    btnSwitch.classList.add('btn-primary');
    btnSwitch.classList.add('geodetection-popup__btn-switch');
    btnSwitch.innerHTML = PopUpMarkup.#btn_switch_label(switch1, switch2, urlExists, destLang);
    btnSwitch.addEventListener('click', changeHandler);

    columnRight.append(contentElRight, contentEl2Right, btnSwitch);

    article.append(columnLeft, columnRight);

    return article;
  }

  /**
   *
   * @param switchLabel1
   * @param switchLabel2
   * @param urlExists
   * @param destLang
   * @returns {*}
   */
  static #btn_switch_label(switchLabel1, switchLabel2, urlExists, destLang) {
    let label = switchLabel1;

    if (destLang === 'fr' && !urlExists) {
      label = switchLabel2;
    }

    return label;
  }

  /**
   *
   * @param translations
   * @param flagPageHandler
   * @returns {HTMLElement}
   */
  static #get_footer(translations, flagPageHandler) {
    const { changeCountryAndLanguage, contentUnavailable } = translations;

    const footer = document.createElement('footer');
    footer.classList.add('geodetection-popup__footer');

    const hint = document.createElement('span');
    hint.classList.add('geodetection-popup__content-not-available');
    hint.textContent = contentUnavailable;

    const btnChangeCountryAndLang = document.createElement('button');
    btnChangeCountryAndLang.classList.add('link');
    btnChangeCountryAndLang.addEventListener('click', flagPageHandler);

    const btnText = document.createElement('span');
    btnText.textContent = changeCountryAndLanguage;

    btnChangeCountryAndLang.append(btnText);
    footer.append(hint, btnChangeCountryAndLang);

    return footer;
  }
}
